import React from "react"
import { Link } from "gatsby"
import { graphql, StaticQuery } from "gatsby"
import Layout from "../layout/layout";

// import Layout from "../layout/layout"
// import Image from "../components/image"
// import SEO from "../layout/seo"

const IndexPage = () => {
  return (
    <Layout>
      <h1>Blog</h1>

      <StaticQuery
        query={indexQuery}
        render={data => {
          return (<>
              {data.allBlogPost.edges.map(({ node }) => (
                      <>
                          <time>{node.date}</time>
                          <h3><Link to={node.slug}>{ node.title}</Link></h3>
                          <p>{node.excerpt}</p>
                      </>
              ))}
          </>)
        }}
      />
    </Layout>
  )
};

const indexQuery = graphql`
  query {
    allBlogPost(filter: {tags: {nin: "draft"}}) {
      edges {
        node {
          id
          slug
          tags
          title
          date(formatString: "YYYY-MM-DD")
          excerpt
        }
      }
    }
  }
`;

export default IndexPage
